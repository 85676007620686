<template>
  <AppRow permission="BONUS_ADMINISTRATION">
    <template v-slot>
      <v-col cols="12">
        <DatePicker
          label="Дата создания (выбрать промежуток)"
          @save="saveDates"
        />
      </v-col>

      <v-col>
        <ApolloQuery
          :query="require('../graphql/queries/getPromocodes.gql')"
          :variables="{
            createdFrom,
            createdTo,
            pageIndex: page,
            pageSize: itemsPerPage,
          }"
        >
          <template
            v-slot="{ result: { error: promocodesError, data: promocodesData }, isLoading: promocodesIsLoading }"
          >
            <!-- Error -->
            <AppNotification
              v-if="promocodesError"
              :message="promocodesError.message"
            />

            <!-- Result -->
            <v-card
              v-else-if="promocodesData || promocodesIsLoading === 1"
              :loading="promocodesIsLoading === 1"
              class="px-4 pb-3"
              flat
            >
              <template v-if="promocodesData">
                <v-card-title>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                    >
                      Промокоды: {{ promocodesData.getPromocodes.total }}
                    </v-col>
                    <v-col class="text-right">
                      <ApolloMutation
                        :mutation="require(editedIndex === -1 ? '../graphql/mutations/createPromocode.gql' : '../graphql/mutations/updatePromocode.gql')"
                        :refetch-queries="refetchQueriesAfterMyMutation"
                        :variables="{
                          id: editedPromocode.id,
                          amount: editedPromocode.amount === '' ? null : editedPromocode.amount,
                          purseType: editedPromocode.purseType,
                          activationCounter: editedPromocode.activationCounter === '' ? null : editedPromocode.activationCounter,
                          activationLimit: editedPromocode.activationLimit === '' ? null : editedPromocode.activationLimit,
                          timeoutSec: editedPromocode.timeoutSec === '' ? null : editedPromocode.timeoutSec * 60,
                          code: editedPromocode.code,
                          newCode: editedPromocode.newCode,
                        }"
                      >
                        <template v-slot="{ mutate, loading, error: createOrEditError }">
                          <v-dialog
                            v-model="dialog"
                            max-width="500px"
                            persistent
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-bind="attrs"
                                class="text-none"
                                color="primary"
                                depressed
                                height="36"
                                large
                                v-on="on"
                              >
                                Создать
                              </v-btn>
                            </template>

                            <v-card :loading="loading">
                              <v-card-title>
                                <span class="text-h5">{{ formTitle }}</span>
                              </v-card-title>

                              <v-card-text>
                                <v-container>
                                  <v-row>
                                    <v-col
                                      cols="12"
                                      sm="6"
                                    >
                                      <v-text-field
                                        v-model.number="editedPromocode.amount"
                                        hide-details="auto"
                                        label="Сумма"
                                      />
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      sm="6"
                                    >
                                      <v-select
                                        v-model="editedPromocode.purseType"
                                        :items="selectPurseType"
                                        background-color="#464646"
                                        class="rounded-lg"
                                        flat
                                        hide-details="auto"
                                        item-text="text"
                                        item-value="key"
                                        label="Валюта"
                                        solo
                                      />
                                    </v-col>
                                    <v-col
                                      cols="12"
                                      sm="6"
                                    >
                                      <v-text-field
                                        v-model.number="editedPromocode.activationLimit"
                                        hide-details="auto"
                                        label="Макс. кол-во активаций"
                                      />
                                    </v-col>
                                    <v-col
                                      v-show="editedIndex !== -1"
                                      cols="12"
                                    >
                                      <v-text-field
                                        v-model.number="editedPromocode.activationCounter"
                                        hide-details="auto"
                                        label="Кол-во активаций"
                                      />
                                    </v-col>
                                    <v-col cols="12">
                                      <v-text-field
                                        v-model.number="editedPromocode.timeoutSec"
                                        hide-details="auto"
                                        label="Истекает через"
                                        suffix="min"
                                      />
                                    </v-col>
                                    <v-col
                                      v-show="editedIndex === -1"
                                      cols="12"
                                    >
                                      <v-text-field
                                        v-model="editedPromocode.code"
                                        hide-details="auto"
                                        label="Код"
                                      />
                                    </v-col>
                                  </v-row>
                                </v-container>
                              </v-card-text>

                              <v-card-actions>
                                <v-spacer />

                                <v-btn
                                  color="primary"
                                  text
                                  @click="close"
                                >
                                  Отмена
                                </v-btn>
                                <v-btn
                                  :disabled="
                                    !editedPromocode.amount
                                      || !editedPromocode.code
                                      || !editedPromocode.activationLimit
                                      || !editedPromocode.timeoutSec
                                  "
                                  color="primary"
                                  text
                                  @click="mutate()"
                                >
                                  {{ buttonText }}
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                          <AppNotification
                            v-if="createOrEditError"
                            :icon-disabled="true"
                            :message="createOrEditError.message"
                          />
                        </template>
                      </ApolloMutation>
                    </v-col>
                  </v-row>
                </v-card-title>
                <v-data-table
                  :headers="headers"
                  :items="promocodesData.getPromocodes.promocodes"
                  :items-per-page="itemsPerPage"
                  disable-sort
                  hide-default-footer
                >
                  <template v-slot:[`item.expire`]="{ item }">
                    {{ item.expire | moment('DD.MM.YYYY, HH:mm') }}
                  </template>

                  <template v-slot:[`item.createdAt`]="{ item }">
                    {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
                  </template>

                  <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                      class="text-none"
                      depressed
                      height="36"
                      large
                      @click="editPromocode(item)"
                    >
                      Редактировать
                    </v-btn>
                  </template>
                </v-data-table>
                <v-divider />

                <div class="text-center pt-3">
                  <v-pagination
                    v-model="page"
                    :length="promocodesData.getPromocodes.pages"
                    :total-visible="9"
                  />

                  <v-select
                    v-model="itemsPerPage"
                    :items="[5, 10, 15]"
                    class="pa-4 pt-5"
                    label="Строк на странице"
                    @change="firstPage"
                  />
                </div>
              </template>
            </v-card>

            <!-- No result -->
            <AppNotification
              v-else
              message="Нет результата :("
            />
          </template>
        </ApolloQuery>
      </v-col>

      <v-col style="margin-top: 2rem">
        Add popup bonuses:
      </v-col>
      <v-col>
        <v-alert v-if="uploaded">
          Bonuses added!
        </v-alert>
        <v-alert v-if="uploadError">
          Adding bonuses error!
        </v-alert>
        <v-row>
          <v-file-input
            clearable
            show-size
            label="File input"
            accept=".csv"
            style="max-width: 500px"
            @change="filesChange"
          ></v-file-input>
          <v-btn
            :disabled="!readFile"
            v-on:click="uploadFile"
            style="margin: auto 0 auto 2rem"
          >
            Upload popup bonuses
          </v-btn>
          <v-checkbox
            label="Is VIP"
            v-model="isVip"
            style="margin: auto 2rem"
            hide-details
          ></v-checkbox>
        </v-row>
      </v-col>

      <v-col style="margin-top: 2rem">
        Delete non activated popup bonuses for users:
      </v-col>
      <v-col>
        <v-alert v-if="delUploaded">
          Bonuses deleted!
        </v-alert>
        <v-alert v-if="delUploadError">
          Deleting bonuses error!
        </v-alert>
        <v-row>
          <v-file-input
            clearable
            show-size
            label="File input"
            accept=".csv"
            style="max-width: 500px"
            @change="delFilesChange"
          ></v-file-input>
          <v-btn
            :disabled="!delReadFile"
            v-on:click="delUploadFile"
            style="margin: auto 0 auto 2rem"
          >
            Upload to delete bonuses
          </v-btn>
        </v-row>
      </v-col>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';
import DatePicker from '@/components/DatePicker.vue';
import AppNotification from '@/components/AppNotification.vue';
import getPromocodes from '@/graphql/queries/getPromocodes.gql';

export default {
  components: {
    AppRow,
    DatePicker,
    AppNotification,
  },
  data() {
    return {
      createdFrom: '',
      createdTo: '',
      dialog: false,
      editedIndex: -1,
      editedPromocode: {
        purseType: 'RUB',
      },
      headers: [
        { text: 'Сумма:', value: 'amount', class: 'white--text text-subtitle-2' },
        { text: 'Валюта:', value: 'purseType', class: 'white--text text-subtitle-2' },
        { text: 'Код:', value: 'code', class: 'white--text text-subtitle-2' },
        { text: 'Кол-во активаций:', value: 'activationCounter', class: 'white--text text-subtitle-2' },
        { text: 'Макс. кол-во активаций:', value: 'activationLimit', class: 'white--text text-subtitle-2' },
        { text: 'Истекает:', value: 'expire', class: 'white--text text-subtitle-2' },
        { text: 'Дата создания:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: '', value: 'action', align: 'center' },
      ],
      page: 1,
      itemsPerPage: 5,
      selectPurseType: [
        { text: 'INR', key: 'INR' },
        { text: 'BTC', key: 'BTC' },
        { text: 'USD', key: 'USD' },
        { text: 'EUR', key: 'EUR' },
        { text: 'ETC', key: 'ETC' },
        { text: 'UAH', key: 'UAH' },
        { text: 'RUB', key: 'RUB' },
        { text: 'LTC', key: 'LTC' },
        { text: 'DEMO', key: 'DEMO' },
        { text: 'ETH', key: 'ETH' },
        { text: 'BRL', key: 'BRL' },
        { text: 'BCH', key: 'BCH' },
        { text: 'BNB', key: 'BNB' },
        { text: 'DASH', key: 'DASH' },
        { text: 'DOGE', key: 'DOGE' },
        { text: 'TRX', key: 'TRX' },
        { text: 'USDT', key: 'USDT' },
        { text: 'XMR', key: 'XMR' },
        { text: 'ZEC', key: 'ZEC' },
        { text: 'XRP', key: 'XRP' },
        { text: 'KZT', key: 'KZT' },
        { text: 'VND', key: 'VND' },
        { text: 'UZS', key: 'UZS' },
        { text: 'IDR', key: 'IDR' },
        { text: 'AZN', key: 'AZN' },
        { text: 'KGS', key: 'KGS' },
        { text: 'PKR', key: 'PKR' },
        { text: 'BDT', key: 'BDT' },
        { text: 'CLP', key: 'CLP' },
        { text: 'PEN', key: 'PEN' },
        { text: 'COP', key: 'COP' },
        { text: 'MXN', key: 'MXN' },
        { text: 'PYG', key: 'PYG' },
        { text: 'UYU', key: 'UYU' },
        { text: 'BOB', key: 'BOB' },
        { text: 'CRC', key: 'CRC' },
        { text: 'GTQ', key: 'GTQ' },
        { text: 'PAB', key: 'PAB' },
        { text: 'NIO', key: 'NIO' },
        { text: 'ARS', key: 'ARS' },
        { text: 'SVC', key: 'SVC' },
      ],
      readFile: null,
      uploaded: false,
      uploadError: false,
      isVip: false,
      delReadFile: null,
      delUploaded: false,
      delUploadError: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Создать промокод' : 'Редактирование';
    },
    buttonText() {
      return this.editedIndex === -1 ? 'Создать' : 'Сохранить';
    },
  },
  methods: {
    saveDates(dates) {
      [this.createdFrom, this.createdTo] = dates;
    },
    refetchQueriesAfterMyMutation() {
      this.close();

      return [{
        query: getPromocodes,
        variables: {
          createdFrom: this.createdFrom,
          createdTo: this.createdTo,
          pageIndex: this.page,
          pageSize: this.itemsPerPage,
        },
      }];
    },
    firstPage() {
      this.page = 1;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedIndex = -1;
        this.editedPromocode = {
          purseType: 'RUB',
        };
      });
    },
    editPromocode(item) {
      this.editedIndex = +item.id;
      this.editedPromocode = { ...item };
      this.dialog = true;
    },
    filesChange(file) {
      this.readFile = file;
    },
    uploadFile() {
      if (!this.readFile) return;

      const formData = new FormData();
      formData.set('file', this.readFile);
      formData.set('isVip', String(this.isVip));

      fetch(`https://${process.env.VUE_APP_BASE_URL}/api/popup-bonuses`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token'),
        },
        body: formData,
      }).then((res, error) => {
        console.log({ res, error });
        if (!error && res.ok) {
          this.readFile = null;
          this.uploadError = false;
          this.uploaded = true;
        } else {
          this.readFile = null;
          this.uploadError = true;
          this.uploaded = false;
        }
      });
    },
    delFilesChange(file) {
      this.delReadFile = file;
    },
    delUploadFile() {
      if (!this.delReadFile) return;

      const formData = new FormData();
      formData.set('file', this.delReadFile);

      fetch(`https://${process.env.VUE_APP_BASE_URL}/api/cancel-popup-bonuses`, {
        method: 'POST',
        headers: {
          Authorization: localStorage.getItem('token'),
        },
        body: formData,
      }).then((res, error) => {
        console.log({ res, error });
        if (!error && res.ok) {
          this.delReadFile = null;
          this.delUploadError = false;
          this.delUploaded = true;
        } else {
          this.delReadFile = null;
          this.delUploadError = true;
          this.delUploaded = false;
        }
      });
    },
  },
};
</script>
